import React from 'react';
import PropTypes from 'prop-types';
import * as ReactGA from 'react-ga';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory, withRouter } from 'react-router';
// MUI imports
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// MUI Icons
import { default as SaveIcon } from '@material-ui/icons/Save';
import { default as PrintIcon } from '@material-ui/icons/Print';
import { default as ApproveIcon } from '@material-ui/icons/Done';
import { default as RejectIcon } from '@material-ui/icons/Close';
import { default as CloneIcon } from '@material-ui/icons/FileCopy';
import { default as ReviewIcon } from '@material-ui/icons/SupervisorAccount';
import { default as ValidateIcon } from '@material-ui/icons/PlaylistAddCheck';

import styles from './styles';
// Constants
import { ROUTES } from '../../constants';
import { gaActions, gaCategory } from '../../constants/reactGA';
// Utils
import PermissionUtils from '../../utils/PermissionUtils';
import DataTransformUtils from '../../utils/DataTransformUtils';
import PlanInstanceUtils from '../../utils/PlanInstanceUtils';
// Actions
import {
  hideActionDrawer,
  hideSidebar,
  hideUnitSelectSidebar,
} from '../../actions/UIActions';
import {
  loadCoursePlan,
  updateCoursePlan,
  updateSnapshot,
} from '../../actions/CoursePlanActions';
import { displayNotification } from '../../actions/NotificationActions';

import { validateCourse } from '../../actions/CourseActions';
// Containers
import UnitSelectContainer from '../../containers/UnitSelectContainer';
import UnitSearchContainer from '../../containers/UnitSearchContainer';
import CourseOverviewContainer from '../../containers/CourseOverviewContainer';
import CourseStatusBannerContainer from '../../containers/CourseStatusBannerContainer';
import CoursePlanEventLogContainer from '../../containers/CoursePlanEventLogContainer';
import PlanInstanceContainer from '../../containers/PlanInstanceContainer';
// Services
import CoursePlanService from '../../services/CoursePlanService';
// Components
import Header from '../../components/Base/Header';
import CourseActionButtons from '../../components/Buttons/CourseActionButtons';
import CourseProgress from '../../components/Course/CourseProgress';
import ValidationModal from '../../components/Modals/ValidationModal';
import DisclaimerModal from '../../components/Modals/DisclaimerModal';
import ClearCourseModal from '../../components/Modals/ClearCourseModal';
import RejectCourseModal from '../../components/Modals/RejectCourseModal';
import ReviewCourseModal from '../../components/Modals/ReviewCourseModal';
import ApproveCourseModal from '../../components/Modals/ApproveCourseModal';
import RequestEnquiryModal from '../../components/Modals/RequestEnquiryModal';
import PreferDesktopBanner from '../../components/Base/PreferDesktopBanner';

/**
 * The plan component is the main page of the app, where students can add and
 * remove teaching periods and units.
 */
class Plan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isClearCourseModalOpen: false,
      isPrintDisclaimerOpen: false,
      approveModalOpen: false,
      reviewModalOpen: false,
      rejectModalOpen: false,
      teachingPeriodsString: '',
      coursePlanName: '',
      isRequestReviewDisclaimerOpen: false,
      isEmailAdvisorLinkModalOpen: false,
      isRequestEnquiryModalOpen: false,
    };
  }

  componentDidMount = () => {
    if (this.props.params.coursePlanId) {
      this.props.loadCoursePlan(this.props.params.coursePlanId);
    }

    window.addEventListener('beforeunload', this.alertChanges);
    this.unregisterLeaveHook = this.props.router.setRouteLeaveHook(
      this.props.route,
      this.routerWillLeave,
    );
  };

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.alertChanges);
    this.unregisterLeaveHook();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.coursePlanLoading && !this.props.coursePlanLoading) {
      const teachingPeriodsString = PlanInstanceUtils.stringifyTeachingPeriods(
        this.props.teachingPeriods,
      );
      this.setState({
        teachingPeriodsString,
        coursePlanName: this.state.coursePlanName
          ? this.state.coursePlanName
          : // If the stored course plan name in state is null
            // update with course plan name passed from props.
            // This should only happen once.
            this.props.coursePlanName,
      });
    }
  }

  alertChanges = e => {
    if (PlanInstanceUtils.isPlanModified(this.state, this.props)) {
      const msg = '';
      e.returnValue = msg;
      e.preventDefault();
      return msg;
    }

    return null;
  };

  routerWillLeave = () => {
    if (PlanInstanceUtils.isPlanModified(this.state, this.props)) {
      return 'You have unsaved changes, would you like to exit?';
    }
  };

  toggleIsClearCourseModalOpen = () => {
    this.setState({
      isClearCourseModalOpen: !this.state.isClearCourseModalOpen,
    });
  };

  openPrintDisclaimer = () => {
    const { hideActionDrawer } = this.props;
    hideActionDrawer();
    this.setState({ isPrintDisclaimerOpen: true });
  };

  closePrintDisclaimer = () => {
    this.setState({ isPrintDisclaimerOpen: false });
  };

  openEmailDisclaimer = () => {
    const { hideActionDrawer } = this.props;
    hideActionDrawer();
    this.setState({ isEmailDisclaimerOpen: true });
  };

  closeEmailDisclaimer = () => {
    this.setState({ isEmailDisclaimerOpen: false });
  };

  openEmailModalAndCloseDisclaimer = () => {
    this.setState({ isEmailModalOpen: true, isEmailDisclaimerOpen: false });
  };

  closeEmailModal = () => {
    this.setState({ isEmailModalOpen: false });
  };

  handleOpenApproveModal = () => {
    const { hideActionDrawer } = this.props;
    hideActionDrawer();
    this.setState({ approveModalOpen: true });
  };

  handleCloseApproveModal = () => {
    this.setState({ approveModalOpen: false });
  };

  handleOpenReviewModal = () => {
    const { hideActionDrawer } = this.props;
    hideActionDrawer();
    this.setState({ reviewModalOpen: true });
  };

  handleCloseReviewModal = () => {
    this.setState({ reviewModalOpen: false });
  };

  handleOpenRejectModal = () => {
    const { hideActionDrawer } = this.props;
    hideActionDrawer();
    this.setState({ rejectModalOpen: true });
  };

  handleCloseRejectModal = () => {
    this.setState({ rejectModalOpen: false });
  };

  handleCourseApproval = () => {
    const {
      snapshotId,
      teachingPeriods,
      advancedStanding,
      coursePlanId,
      coursePlanName,
    } = this.props;

    CoursePlanService.saveCoursePlan(
      teachingPeriods,
      advancedStanding,
      coursePlanId,
      coursePlanName,
    )
      .then(res => {
        const teachingPeriodsString = PlanInstanceUtils.stringifyTeachingPeriods(
          this.props.teachingPeriods,
        );
        this.setState({
          coursePlanName,
          teachingPeriodsString,
        });
        const newSnapshotId = res.snapshot.snapshotId;
        return CoursePlanService.approveSnapshot(newSnapshotId);
      })
      .then(resp => {
        this.props.displayNotification('Course Plan Approved');
        const { studentId } = resp;
        this.setState({ approveModalOpen: false });
        ReactGA.ga(tracker => {
          ReactGA.event({
            category: gaCategory.FLOW,
            action: gaActions.FLOW.approvePlan,
            label: `{${snapshotId}}`,
          });
        });

        if (studentId) {
          browserHistory.push(`${ROUTES.STUDENT_SEARCH}/${studentId}`);
        } else {
          throw new Error('studentId came back undefined', resp);
        }
      })
      .catch(err => {
        console.error(err);
        this.props.displayNotification('Error Approving Course Plan');
        this.setState({ approveModalOpen: false });
      });
  };

  handleCloneCourse = () => {
    const {
      user,
      teachingPeriods,
      advancedStanding,
      coursePlanName,
      courseCode,
      coursePlanId,
      currentStudentId,
    } = this.props;

    CoursePlanService.saveCoursePlan(
      teachingPeriods,
      advancedStanding,
      coursePlanId,
      coursePlanName,
    )
      .then(resp => {
        const teachingPeriodsString = PlanInstanceUtils.stringifyTeachingPeriods(
          this.props.teachingPeriods,
        );
        this.setState({
          coursePlanName,
          teachingPeriodsString,
        });

        if ( currentStudentId === null &&  (user.isAusStudent || user.isMalaysiaStudent) ) {
          return CoursePlanService.cloneCoursePlan(
              DataTransformUtils.getMonPlanBlob(teachingPeriods, advancedStanding),
              coursePlanName,
              courseCode
          );
        } else {
          return CoursePlanService.cloneCoursePlanForStudent(
              DataTransformUtils.getMonPlanBlob(teachingPeriods, advancedStanding),
              coursePlanName,
              courseCode,
              currentStudentId
          );
        }

      })
      .then(resp => {
        this.props.displayNotification('Course Plan Cloned');
        if ( user.isAusStudent ) {
          browserHistory.push(ROUTES.LIST_PLANS);
        } else {
          browserHistory.push(`${ROUTES.STUDENT_SEARCH}/${currentStudentId}`);
        }

      })
      .catch(err => {
        console.error(err);
        this.props.displayNotification('Error Cloning Course Plan');
      });
  };

  handleValidateCoursePlan = () => {
    const { teachingPeriods, validateCourse, hideActionDrawer } = this.props;

    hideActionDrawer();
    validateCourse(teachingPeriods);
  };

  // Course Advisor Force Review Plan
  handleCourseReview = () => {
    const { snapshotId, displayNotification, updateSnapshot } = this.props;

    this.handleCloseReviewModal();
    CoursePlanService.submitForReview(snapshotId)
      .then(updatedSnapshot => {
        displayNotification('Course plan ready for review');
        updateSnapshot(updatedSnapshot);
        ReactGA.ga(tracker => {
          ReactGA.event({
            category: gaCategory.FLOW,
            action: gaActions.FLOW.requestReview.ADVISOR,
            label: `{${snapshotId}}`,
          });
        });
      })
      .catch(err => {
        console.error(err);
        displayNotification('Error: Could not submit course plan for review');
      });
  };

  // If student submits enquiry successfully
  handleEnquirySubmissionSuccess = resp => {
    const { updateCoursePlan } = this.props;
    const snapshot = resp.snapshot;

    ReactGA.ga(tracker => {
      ReactGA.event({
        category: gaCategory.FLOW,
        action: gaActions.FLOW.requestReview.STUDENT,
        label: `{${snapshot.snapshotId}}`,
      });
    });

    updateCoursePlan(resp);
  };

  openRequestEnquiryModal = () => {
    this.props.hideActionDrawer();
    this.setState({ isRequestEnquiryModalOpen: true });
  };

  closeRequestEnquiryModal = () => {
    this.setState({ isRequestEnquiryModalOpen: false });
  };

  closeEmailAdvisorLinkModal = () =>
    this.setState({ isEmailAdvisorLinkModalOpen: false });

  handleRejectCourse = () => {
    const {
      snapshotId,
      displayNotification,
      updateSnapshot,
      teachingPeriods,
      advancedStanding,
      coursePlanId,
      coursePlanName,
    } = this.props;

    this.handleCloseRejectModal();

    CoursePlanService.saveCoursePlan(
      teachingPeriods,
      advancedStanding,
      coursePlanId,
      coursePlanName,
    )
      .then(res => {
        const teachingPeriodsString = PlanInstanceUtils.stringifyTeachingPeriods(
          this.props.teachingPeriods,
        );
        this.setState({
          coursePlanName,
          teachingPeriodsString,
        });
        const newSnapshotId = res.snapshot.snapshotId;
        return CoursePlanService.rejectSnapshot(newSnapshotId);
      })
      .then(updatedSnapshot => {
        displayNotification('Course Plan Sent Back To Student');
        updateSnapshot(updatedSnapshot);
        ReactGA.ga(tracker => {
          ReactGA.event({
            category: gaCategory.FLOW,
            action: gaActions.FLOW.rejectPlan,
            label: `{${snapshotId}}`,
          });
        });
      })
      .catch(err => {
        console.error(err);
        displayNotification(
          'Error: Could not send course plan back to student',
        );
      });
  };

  handleSaveCourse = () => {
    const {
      teachingPeriods,
      advancedStanding,
      coursePlanId,
      coursePlanName,
      hideActionDrawer,
    } = this.props;

    hideActionDrawer();

    CoursePlanService.saveCoursePlan(
      teachingPeriods,
      advancedStanding,
      coursePlanId,
      coursePlanName,
    )
      .then(() => {
        const teachingPeriodsString = PlanInstanceUtils.stringifyTeachingPeriods(
          this.props.teachingPeriods,
        );
        this.setState({
          coursePlanName,
          teachingPeriodsString,
        });
        this.props.displayNotification('Course Plan Saved');
      })
      .catch(err => {
        console.error(err);
        this.props.displayNotification('Error Saving Course Plan');
      });
  };

  renderUserActions = () => {
    const { user, isAuth, status } = this.props;

    const validateButton = (
      <ListItem key="validate" button onClick={this.handleValidateCoursePlan}>
        <ListItemIcon>
          <ValidateIcon />
        </ListItemIcon>
        <ListItemText>Validate Course Plan</ListItemText>
      </ListItem>
    );

    const approvalButton = (
      <ListItem key="approve" button onClick={this.handleOpenApproveModal}>
        <ListItemIcon>
          <ApproveIcon />
        </ListItemIcon>
        <ListItemText>Approve Course Plan</ListItemText>
      </ListItem>
    );

    const saveButton = (
      <ListItem
        disabled={!PlanInstanceUtils.isPlanModified(this.state, this.props)}
        key="save"
        button
        onClick={this.handleSaveCourse}>
        <ListItemIcon>
          <SaveIcon />
        </ListItemIcon>
        <ListItemText>Save Course Plan</ListItemText>
      </ListItem>
    );

    const cloneButton = (
      <ListItem key="clone" button onClick={this.handleCloneCourse}>
        <ListItemIcon>
          <CloneIcon />
        </ListItemIcon>
        <ListItemText>Clone Course Plan</ListItemText>
      </ListItem>
    );

    const requestReviewButton = (
      <ListItem
        key="request-review"
        button
        onClick={this.openRequestEnquiryModal}>
        <ListItemIcon>
          <ReviewIcon />
        </ListItemIcon>
        <ListItemText>Ready For Review?</ListItemText>
      </ListItem>
    );

    const rejectButton = (
      <ListItem key="send-back" button onClick={this.handleOpenRejectModal}>
        <ListItemIcon>
          <RejectIcon />
        </ListItemIcon>
        <ListItemText>Send Back To Student</ListItemText>
      </ListItem>
    );

    const reviewButton = (
      <ListItem key="review" button onClick={this.handleOpenReviewModal}>
        <ListItemIcon>
          <ReviewIcon />
        </ListItemIcon>
        <ListItemText>Review Course Plan</ListItemText>
      </ListItem>
    );

    const printButton = (
      <ListItem key="print" button onClick={this.openPrintDisclaimer}>
        <ListItemIcon>
          <PrintIcon />
        </ListItemIcon>
        <ListItemText>Print Course Plan</ListItemText>
      </ListItem>
    );

    const getFeedbackButton = (
      <ListItem key="feedback" button onClick={this.openRequestEnquiryModal}>
        <ListItemIcon>
          <ReviewIcon />
        </ListItemIcon>
        <ListItemText>Get Advisor Feedback</ListItemText>
      </ListItem>
    );

    if (isAuth) {
      return PermissionUtils.renderAllowedUserActionButtons({
        user,
        status,
        approvalButton,
        saveButton,
        cloneButton,
        requestReviewButton,
        rejectButton,
        reviewButton,
        printButton,
        validateButton,
        getFeedbackButton,
      });
    }

    return [];
  };

  render() {
    const {
      reviewModalOpen,
      rejectModalOpen,
      approveModalOpen,
      isPrintDisclaimerOpen,
      isClearCourseModalOpen,
      isRequestEnquiryModalOpen,
    } = this.state;

    const coursePlanId = this.props.params.coursePlanId || null;
    const {
      showingSidebar,
      hideSidebar,
      hideUnitSelectSidebar,
      showingUnitSelectSidebar,
      teachingPeriods,
      advancedStanding,
      snapshotId,
      classes,
      isAuth,
      status,
      user,
      coursePlanName,
    } = this.props;

    if (!this.props.user) {
      window.location = `${window.location.origin}`;
    }

    const coursePlanLink = `https://monplan.apps.monash.edu${
      ROUTES.EDIT_PLAN
    }/${coursePlanId}`;

    return (
      <div id="plan-page" style={{ background: '#eee ', minHeight: '100vh' }}>
        <Header title="Menu">
          <React.Fragment>{this.renderUserActions()}</React.Fragment>
        </Header>

        {/* PRINT DISCLAIMER */}
        <DisclaimerModal
          open={isPrintDisclaimerOpen}
          onDecline={this.closePrintDisclaimer}
          onAccept={() =>
            browserHistory.push(
              coursePlanId !== undefined
                ? `${ROUTES.PRINT_PLAN}/${coursePlanId}`
                : ROUTES.PRINT_PLAN,
            )
          }
        />
        <PreferDesktopBanner />
        <ApproveCourseModal
          open={approveModalOpen}
          isMalaysiaAdviser={user.isMalaysiaStaff}
          onClose={this.handleCloseApproveModal}
          onApprove={this.handleCourseApproval}
        />
        <RejectCourseModal
          open={rejectModalOpen}
          onClose={this.handleCloseRejectModal}
          onReject={this.handleRejectCourse}
        />
        <ReviewCourseModal
          open={reviewModalOpen}
          onClose={this.handleCloseReviewModal}
          onReview={this.handleCourseReview}
        />

        <RequestEnquiryModal
          onClose={this.closeRequestEnquiryModal}
          open={isRequestEnquiryModalOpen}
          snapshotId={snapshotId}
          teachingPeriods={teachingPeriods}
          advancedStanding={advancedStanding}
          coursePlanId={coursePlanId}
          coursePlanName={coursePlanName}
          isAusStudent={user.isAusStudent}
          isMalaysiaStudent={user.isMalaysiaStudent}
          onSuccess={this.handleEnquirySubmissionSuccess}
        />

        <ClearCourseModal
          open={isClearCourseModalOpen}
          onClose={this.toggleIsClearCourseModalOpen}
        />
        <ValidationModal />
        <Drawer onClose={hideSidebar} anchor="right" open={showingSidebar}>
          <UnitSearchContainer
            hideSidebar={this.props.hideSidebar}
            focusActive={showingSidebar}
          />
        </Drawer>
        <Drawer
          onClose={hideUnitSelectSidebar}
          anchor="right"
          open={showingUnitSelectSidebar}>
          <UnitSelectContainer
            hideSidebar={this.props.hideUnitSelectSidebar}
            focusActive={showingUnitSelectSidebar}
          />
        </Drawer>
        <div
          style={{
            padding: '16px 0 128px',
            maxWidth: 1400,
            width: '100%',
            margin: '0 auto',
          }}>
          <div style={{ margin: '0 auto' }}>
            <div className={classes.mobileFlex}>
              <MediaQuery minWidth={1140}>
                <CourseActionButtons
                  isAuth={isAuth}
                  status={status}
                  user={user}
                  hasUnsavedChanges={PlanInstanceUtils.isPlanModified(
                    this.state,
                    this.props,
                  )}
                  onApprovalClick={this.handleOpenApproveModal}
                  onSaveClick={this.handleSaveCourse}
                  onCloneClick={this.handleCloneCourse}
                  onRequestReviewClick={this.openRequestEnquiryModal}
                  onRejectClick={this.handleOpenRejectModal}
                  onReviewClick={this.handleOpenReviewModal}
                  onPrintClick={this.openPrintDisclaimer}
                  onValidateClick={this.handleValidateCoursePlan}
                />
              </MediaQuery>
              <Paper classes={{ root: classes.coursePlanContainer }}>
                <CourseStatusBannerContainer
                  coursePlanLink={coursePlanLink}
                  snapshotId={snapshotId}
                />
                <CourseOverviewContainer style={{ minHeight: 200 }} />
                <CoursePlanEventLogContainer />
                {coursePlanId ? (
                  <PlanInstanceContainer coursePlanId={coursePlanId} />
                ) : (
                  <PlanInstanceContainer />
                )}
              </Paper>
              <MediaQuery minWidth={1400}>
                  <div className={classes.courseProgressContainer}>
                    <CourseProgress />
                  </div>
              </MediaQuery>
            </div>
            <MediaQuery maxWidth={1400}>
                <div className={classes.courseProgressContainer}>
                  <CourseProgress />
                </div>
            </MediaQuery>
          </div>
        </div>
      </div>
    );
  }
}

Plan.propTypes = {
  showAddUnit: PropTypes.bool,
  showingSidebar: PropTypes.bool,
  showSidebar: PropTypes.func,
  hideSidebar: PropTypes.func,
  showStatus: PropTypes.bool,
  courseErrors: PropTypes.object,
};

/**
 * Inject showingSidebar indicating if the sidebar is showing or not, and
 * courseErrors which contains a list of errors in the course plan.
 */
const mapStatetoProps = state => {
  return {
    showingSidebar: state.UI.showingSidebar,
    showingUnitSelectSidebar: state.UI.showingUnitSelectSidebar,
    courseErrors: state.Validation.courseErrors,
    teachingPeriods: state.PlanInstance.teachingPeriods,
    advancedStanding: state.AdvancedStanding.advancedStanding,
    user: state.User.user,
    coursePlanName: state.CoursePlan.coursePlanName,
    coursePlanId: state.CoursePlan.coursePlanId,
    isAuth: state.User.isAuth,
    snapshotId: state.CoursePlan.snapshotId,
    approval: state.CoursePlan.approval,
    courseCode: state.Handbook.courseInfo.courseCode,
    coursePlanLoading: state.CoursePlan.coursePlanLoading,
    status: state.CoursePlan.status,
    currentStudentId: state.CoursePlan.currentStudentId,
  };
};

/**
 * Provide UI dispatchers as props. Used for showing/hiding add unit sidebar.
 */
const mapDispatchToProps = dispatch => {
  const actionBundle = {
    hideActionDrawer,
    hideUnitSelectSidebar,
    loadCoursePlan,
    displayNotification,
    hideSidebar,
    updateSnapshot,
    updateCoursePlan,
    validateCourse,
  };
  return bindActionCreators(actionBundle, dispatch);
};

const PlanWithStyles = withStyles(styles)(Plan);
export default withRouter(
  connect(
    mapStatetoProps,
    mapDispatchToProps,
  )(PlanWithStyles),
);
